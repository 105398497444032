import { createContext, useContext } from 'react'

export const DomainContext = createContext()

export const useDomainContext = () => useContext(DomainContext);


export const DomainContextProvider = ({ children }) => {
  const currentUrl = new URL(window.location.href);
  const hostName = currentUrl.hostname;
  const parts = hostName.split('.');
  // const subdomain = parts.length > 1 ? parts[0] : null;
  // const domain = parts.slice(-2).join('.');
  let subdomain = null;
  let domain = null;

  // console.log('parts', parts)
  
  if (parts.length === 1) {
    // If there's only one part, it's the domain
    domain = parts[0];
  } else if (parts.length === 2) {
    // If there are exactly two parts, there's no subdomain unless it's localhost
    if (parts[1] === 'localhost') {
      subdomain = parts[0];
      domain = parts[1];
    } else {
      domain = parts.join('.');
    }
  } else {
    // Otherwise, there's at least one subdomain
    subdomain = parts[0];
    domain = parts.slice(-2).join('.');
  }
  

  // console.log('domain context:', domain, "subdomain", subdomain)

  return (
    <DomainContext.Provider value={{ subdomain, domain }}>
      {children}
    </DomainContext.Provider>
  )

}