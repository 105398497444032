import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from "./context/AuthContext"
import { AuthSubContextProvider } from "./context_sub/AuthContext"
import { ThemeContextProvider } from "./context_sub/ThemeContext"
import { PlanContextProvider } from "./context/PlanContext"
import { DomainContextProvider } from "./context_sub/DomainContext"
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  <QueryClientProvider client={queryClient}>
    <DomainContextProvider>
      <AuthSubContextProvider>
        <AuthContextProvider>
          <PlanContextProvider>
            <ThemeContextProvider>
              <App />
            </ThemeContextProvider>
          </PlanContextProvider>
        </AuthContextProvider>
      </AuthSubContextProvider>
    </DomainContextProvider>
    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
  </QueryClientProvider>
);
