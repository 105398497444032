import { lazy, Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useAuthContext } from './hooks/useAuthContext'
import { useAuthContext as useAuthContextSub } from './hooks_sub/useAuthContext'
import { useDomainContext } from './context_sub/DomainContext'
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as CropLoadingIcon } from "./assets/loading-spinner.svg";

const Home = lazy(() => import('./pages/main/Home'));
const Success = lazy(() => import('./pages/main/Success'));
const Signup = lazy(() => import('./pages/main/Signup'));
const Login = lazy(() => import('./pages/main/Login'));
const Admin = lazy(() => import('./pages/main/Admin'));
const ForgotPassword = lazy(() => import('./pages/main/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/main/ResetPassword'));
const Privacy = lazy(() => import('./pages/main/Privacy'));
const Terms = lazy(() => import('./pages/main/Terms'));
const Contact = lazy(() => import('./pages/main/Contact'));
const Help = lazy(() => import('./pages/main/Help/Help'));
const HelpArticle = lazy(() => import('./pages/main/Help/HelpArticle'));
const Dashboard = lazy(() => import('./pages/main/Dashboard'));
const Settings = lazy(() => import('./pages/main/Settings'));
const StoreSettings = lazy(() => import('./pages/main/StoreSettings'));
const Pricing = lazy(() => import('./pages/main/Pricing'));
const Categories = lazy(() => import('./pages/main/Categories'));
const Pages = lazy(() => import('./pages/main/Pages'));
const EditPage = lazy(() => import('./pages/main/EditPage'));
const Listings = lazy(() => import('./pages/main/Listings'));
const ListingPage = lazy(() => import('./pages/main/ListingPage'));
const Reviews = lazy(() => import('./pages/main/Reviews'));
const Orders = lazy(() => import('./pages/main/Orders'));
const Order = lazy(() => import('./pages/main/Order'));
const Menus = lazy(() => import('./pages/main/Menus'));
const NotFound = lazy(() => import('./pages/NotFound'));
const RequestHandler = lazy(() => import('./routes/subRoutes'));



function App() {
  const { user } = useAuthContext()
  const { dispatch } = useAuthContextSub()
  const { domain, subdomain } = useDomainContext()
  // console.log('App: domain', domain, 'sub', subdomain, 'user', user)

  useEffect(() => {
    // see if user is on a subdomain or different domain than the main site
    let onSubdomain = false;
    if (subdomain && subdomain !== 'www') {
      onSubdomain = true;
    } else if (domain !== "localhost" && domain !== "doebuilder.com") {
      onSubdomain = true;
    }

    // If the user is not logged in, assign a UUID
    if (!user && onSubdomain) {
      const userStorage = localStorage.getItem('user');
      if (!userStorage?.uuid) {
        const newUUID = uuidv4();
        localStorage.setItem('user', JSON.stringify({
          'uuid': newUUID
        }));

        // dispatch to subdomain context
        dispatch({ type: 'IDENTIFY', payload: { 'uuid': newUUID } })

      }
    }
  }, [user]);

  let router;
  if ((domain === "localhost" || domain === "doebuilder.com") && (subdomain === "www" || subdomain === null)) {
    router = createBrowserRouter([
      {
        path: "/",
        element: <Home />,
        errorElement: <div>error</div>,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/success",
        element: <Success />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/login",
        element: !user ? <Login /> : <Navigate to="/dashboard" />,
      },
      {
        path: "/superadminpanel",
        element: !user ? <Navigate to="/login" /> : <Admin />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/help-center",
        element: <Help />,
      },
      {
        path: "/help-center/:helpSlug",
        element: <HelpArticle />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/dashboard",
        element: user ? <Dashboard /> : <Navigate to="/login" />,
      },
      {
        path: "/settings",
        element: user ? <Settings /> : <Navigate to="/login" />,
      },
      {
        path: "/store-settings",
        element: user ? <StoreSettings /> : <Navigate to="/login" />,
      },
      {
        path: "/categories",
        element: user ? <Categories /> : <Navigate to="/login" />,
      },
      {
        path: "/pages",
        element: user ? <Pages /> : <Navigate to="/login" />,
      },
      {
        path: "/page/:pageId",
        element: user ? <EditPage /> : <Navigate to="/login" />,
      },
      {
        path: "/listings",
        element: user ? <Listings /> : <Navigate to="/login" />,
      },
      {
        path: "/reviews",
        element: user ? <Reviews /> : <Navigate to="/login" />,
      },
      {
        path: "/orders",
        element: user ? <Orders /> : <Navigate to="/login" />,
      },
      {
        path: "/orders/:orderId",
        element: user ? <Order /> : <Navigate to="/login" />,
      },
      {
        path: "/listings/:listingId",
        element: user ? <ListingPage /> : <Navigate to="/login" />,
      },
      {
        path: "/menus",
        element: user ? <Menus /> : <Navigate to="/login" />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ]);
  } else {
    router = createBrowserRouter([
      {
        path: "*",
        element: <RequestHandler />,
      },
    ])
  }

  return (
    <div className="App h-full flex flex-col">
      <Suspense fallback={<LoadingFallback />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
}


const LoadingFallback = () => {
  return (
    <div className='w-full h-full flex justify-center items-center'>

      <div className='mb-12'>
        <div className='flex justify-center mb-4'>
          <CropLoadingIcon className='animate-spin' />
        </div>
        <div className='text-xl'>Loading...</div>
      </div>

    </div>
  )

}
export default App;