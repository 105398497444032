import { createContext, useReducer } from 'react'

export const ThemeContext = createContext()

export const themeReducer = (state, action) => {
  switch (action.type) {
    case 'SET_THEME':
      localStorage.setItem('theme', JSON.stringify({ ...action.payload }))
      return { theme: action.payload }
    default:
      return state
  }
}

export const ThemeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, { 
    theme: null
  })

  return (
    <ThemeContext.Provider value={{ ...state, dispatch }}>
      { children }
    </ThemeContext.Provider>
  )

}