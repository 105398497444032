import { createContext, useState, useEffect } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { useLogout } from '../hooks/useLogout'

export const PlanContext = createContext()

export const PlanContextProvider = ({ children }) => {
  const [planData, setPlanData] = useState({
    activePlan: false,
  })
  const { user } = useAuthContext()
  const { logout } = useLogout()


  const fetchPlanInfo = () => {
    fetch('/api/billing/context', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      }
    }).then(r => r.json())
      .then(data => {
        const { error, activePlan, } = data
        // console.log('activeplan context', activePlan)
        if (error) {
          if (error == "Request is not authorized") {
            logout()
          }
        }

        setPlanData({
          activePlan,
        })

      })

  }

  // get initial state when someone loads a session
  useEffect(() => {
    if (user?.token) {
      fetchPlanInfo()
    }
  }, [user])


  return (
    <PlanContext.Provider value={{ ...planData, setPlanData }}>
      {children}
    </PlanContext.Provider>
  )

}